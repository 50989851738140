<template>
  <!-- 行驶数据 -->
  <div class="chart">
    <svg :class="$style.svgChart" width="120" height="36" viewBox="0 0 120 36" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient x1="42.615%" y1="-22.906%" x2="58.238%" y2="133.008%" id="fColor">
                <stop stop-color="#F15887" offset="0%" />
                <stop stop-color="#FE9B86" offset="100%" />
            </linearGradient>
            <linearGradient x1="43.844%" y1="0%" x2="56.156%" y2="100%" id="mColor">
                <stop stop-color="#2DC9EB" offset="0%" />
                <stop stop-color="#14D2B8" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fill-rule="evenodd" transform="translate(.79)">
            <path v-for="i in 20" :key="i" 
              :transform="`translate(${(i - 1)%10 * 12.432}, ${~~((i - 1) / 10) * 21.4})`" 
              :d="sexPaths[mRatio >= i * 5 ? 0 : 1]" 
              :fill="`url(#${mRatio >= i * 5 ? 'mColor' : 'fColor'})`" />
        </g>
    </svg>

    <!-- <svg :class="$style.svgChart" width="68" height="68" viewBox="0 0 68 68" xmlns="http://www.w3.org/2000/svg">
      <g stroke-width="10" fill="none" fill-rule="evenodd">
        <circle stroke="#9D54FF" cx="34" cy="34" r="29" />
        <path :d="`M34 5${path}`" stroke="#00F6E5" stroke-linecap="round" />
      </g>
    </svg> -->

    <ul :class="$style.ratioList">
      <li :class="$style.m">
        <span>男性</span>
        <strong>{{parseInt(mRatio)}}%</strong>
      </li>
      <li :class="$style.f">
        <span>女性</span>
        <strong>{{100 - parseInt(mRatio)}}%</strong>
      </li>
    </ul>
  </div>
</template>

<style module lang="scss">
.svgChart {
  display: block;
  margin: 1rem auto;
  width: 100%;
  height: auto;
}
.ratioList {
  li {
    display: flex;
    margin-top: .2rem;
    align-items: center;
    line-height: 1rem;
    color: #888FCD;

    strong {
      text-align: right;
      min-width: 2.5rem;
      color: #fff;
      font-weight: normal;
    }

    &::before {
      display: block;
      margin-right: .3rem;
      width: .4rem;
      height: .4rem;
      content: '';
      overflow: hidden;
      border-radius: 50%;
      background: #9D54FF;
    }

    &.m::before {
      background-color: #00F6E5;
    }
  }
}
</style>

<script>
import { TweenLite } from 'gsap';

// const { remUnit = 20 } = window;
// const cPath = (val) => {
//   // 超过一半，先画半圆
//   const half = val > 50 ? 'A29 29 0 0 1 34 63' : '';
//   const r = 29; // 圆半径
//   const deg = (val / 100) * 360  - 90; // 当前角度
//   const x = 34 + r * Math.cos((deg * Math.PI) / 180);
//   const y = 34 + r * Math.sin((deg * Math.PI) / 180);

//   return `${half}A29 29 0 0 1 ${x} ${y}`;
// }

export default {
  props: {
    title: {
      type: String,
    },
    large: {
      type: Boolean,
      default: false
    },
    vData: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      sexPaths: [
        'M2.268 3.637h1.993A1.466 1.466 0 0 1 5.81 5.165c.005 1.304 0 2.608 0 3.945H4.721v5.485h-2.93V9.139H.712C.704 9 .693 8.897.693 8.793v-3.59a1.47 1.47 0 0 1 1.575-1.566zM4.282.427A1.454 1.454 0 0 1 3.249 2.91 1.455 1.455 0 1 1 4.282.427z',
        'M4.433 4.206c.103.126.184.27.238.423.607 1.799 1.21 3.599 1.809 5.401.016.047.026.098.053.205H4.37v4.36h-2.2v-4.35H0c.004-.07.014-.137.029-.205.605-1.81 1.192-3.628 1.827-5.427a1.502 1.502 0 0 1 2.577-.407zM3.255 0c.81.01 1.46.669 1.458 1.478A1.459 1.459 0 1 1 3.255 0z'
      ],
      mRatio: 0
    };
  },
  watch: {
    vData(newVal = {}) {
      const { mSex = 0 } = newVal;

      TweenLite.to(this.$data, 1, {
        mRatio: mSex
      });
    },
  },
  // computed: {
  //   path() {
  //     const { mRatio } = this;

  //     return cPath(mRatio);
  //   }
  // },
  mounted() {
    const { mSex = 0 } = this.vData;

    TweenLite.to(this.$data, 1, {
      mRatio: mSex
    });
  },
};
</script>